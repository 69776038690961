import './newsletter_box.css'
import Swal from 'sweetalert2'

window.addEventListener("load", () => {
  const element = document.querySelector("#js_newsletter_inscription_form")
  if (element) {
    element.addEventListener("ajax:success", (event) => {
      if (event.detail[0].success) {
        window._axcb.push(function(axeptio) {
          axeptio.on("cookies:complete", function(choices) {
            if(choices.google_analytics) {
              gtag('event', 'newsletter', {'event_category' : 'inscription','event_label' : 'newsletter_inscription'});
            }
          })
        })
        Swal.fire({
          position: 'top-end',
          title: 'Inscription prise en compte',
          showConfirmButton: false,
          customClass: {
            container: 'side-alert-container',
            popup: 'side-alert-popup',
            header: 'side-alert-header',
            title: 'side-alert-title'
          },
          timer: 2000
        })
      } else {
        Swal.fire({
          position: 'top-end',
          title: event.detail[0].error ? event.detail[0].error : "Une erreur est survenue",
          showConfirmButton: false,
          customClass: {
            container: 'side-alert-container',
            popup: 'side-alert-popup',
            header: 'side-alert-header',
            title: 'side-alert-title'
          },
          timer: 2000
        })
      }
    })

    element.addEventListener("ajax:error", () => {
      Swal.fire({
        position: 'top-end',
        title: 'Une erreur est survenue',
        showConfirmButton: false,
        customClass: {
          container: 'side-alert-container',
          popup: 'side-alert-popup',
          header: 'side-alert-header',
          title: 'side-alert-title'
        },
        timer: 2000
      })
    })
  }
})
