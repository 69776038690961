import './navbar.css'

export const manageNavbar = () => {
  const button = document.querySelector('.navbar__mobile-menu')
  const sidebar = document.querySelector('.js-mobile-sidebar')
  if (button && sidebar) {
    button.addEventListener('click', (event) => {
      sidebar.classList.toggle('visible')
      button.classList.add('.hide')
    })
    const closeButton = sidebar.querySelector('.js-close')
    if (closeButton) {
      closeButton.addEventListener('click', (event) => {
        sidebar.classList.remove('visible')
      })
    }
  }
}

export const manageDropDownMenu = () => {
  const ressourcesLink = document.getElementById("ressources");
  const discoverLink = document.getElementById("discover");
  const resourcesDropdownMenu = document.getElementById('ressources-dropdown')
  const discoverDropdownMenu = document.getElementById('discover-dropdown')

  ressourcesLink.addEventListener("click", function(event) {
    event.preventDefault();
    resourcesDropdownMenu.classList.toggle("show");
  });

  document.addEventListener("click", function(event) {
    const isClickInside = resourcesDropdownMenu.contains(event.target) || ressourcesLink.contains(event.target);
    if (!isClickInside) {
      resourcesDropdownMenu.classList.remove("show");
    }
  });

  discoverLink.addEventListener("click", function(event) {
    event.preventDefault();
    discoverDropdownMenu.classList.toggle("show");
  });

  document.addEventListener("click", function(event) {    
    const isClickInside = discoverDropdownMenu.contains(event.target) || discoverLink.contains(event.target);
    if (!isClickInside) {
      discoverDropdownMenu.classList.remove("show");
    }
  });
}

export const manageButtonDisplay = () => {
  const signInButton = document.getElementById("sign_in");
  const demoRequestButton = document.getElementById("demo_request");

  if (signInButton && demoRequestButton) {
    window.addEventListener("scroll", function(event) {
    if (window.scrollY > 96) {
      signInButton.classList.add('hide')
      demoRequestButton.classList.remove('hide')
    } else {
      signInButton.classList.remove('hide')
      demoRequestButton.classList.add('hide')
    }
  })
  }
}
