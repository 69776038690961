import './footer.css'

export const manageFooterPhoneNumber = () => {
  const hidePhoneNumber = document.querySelector('.see_footer_phone_number')
  const phoneNumber = document.querySelector('.footer_phone_number')
  if (hidePhoneNumber && phoneNumber) {
    hidePhoneNumber.addEventListener('click', (event) => {
      window._axcb.push(function(axeptio) {
        axeptio.on("cookies:complete", function(choices) {
          if(choices.google_analytics) {
            gtag('event', 'intention-appel', {'event_category' : 'contact','event_label' : 'footer_phone_number'});
          }
        })
      })
      hidePhoneNumber.classList.add('hide')
      phoneNumber.classList.remove('hide')
    })
  }
}

export const manageSocialNetworksGtag = () => {
  window._axcb.push(function(axeptio) {
    axeptio.on("cookies:complete", function(choices) {
      if(choices.google_analytics) {
        const facebook = document.getElementById('facebook')
        const linkedin = document.getElementById('linkedin')
        const instagram = document.getElementById('instagram')

        if (facebook && linkedin && instagram) {
          facebook.addEventListener('click', (event) => {
            gtag('event', 'reseaux-sociaux', {'event_category': 'liens-sortants','event_label': 'facebook'});
          })
          linkedin.addEventListener('click', (event) => {
            gtag('event', 'reseaux-sociaux', {'event_category': 'liens-sortants','event_label': 'linkedin'});
          })
          instagram.addEventListener('click', (event) => {
            gtag('event', 'reseaux-sociaux', {'event_category': 'liens-sortants','event_label': 'instagram'});
          })
        }
      }
    })
  })
}
