import './page.css'
import Swal from 'sweetalert2'

export const manageDemoRequest = () => {
  const meetingIframe = document.querySelector('.meetings-iframe-container')
  if (meetingIframe) {
    window.addEventListener('message', (event) => {
      if (event.data.meetingBookSucceeded === true) {
        window._axcb.push((axeptio) => {
          axeptio.on('cookies:complete', (choices) => {
            if (choices.google_analytics) {
              gtag('event', 'purchase', {
                'transaction_id': `id_transaction_aléatoire  - ${new Date().toISOString()}`,
                'event_category': 'ecommerce',
                'event_label': 'contact-demo',
                'affiliation': 'contact-demo',
                'value': 0,
                'items': [
                  {
                    'name': 'contact-demo',
                    'quantity': 1,
                    'price': 0
                  }]
              })
            }

            if(choices.facebook_pixel) {
              if(typeof fbq === 'undefined') {
                !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
                n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
                document,'script','https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '421897455141667');
                fbq('track', 'PageView');
                fbq('track', 'Lead');
              } else {
                fbq('track', '421897455141667');
                fbq('track', 'Lead');
              }
            }
          })
        })
      }
    })
  }
}

export const managePagePhoneNumber = () => {
  const hidePhoneNumber = document.querySelector('.see_page_phone_number')
  const phoneNumber = document.querySelector('.page_phone_number')
  if (hidePhoneNumber && phoneNumber) {
    hidePhoneNumber.addEventListener('click', (event) => {
      window._axcb.push((axeptio) => {
        axeptio.on('cookies:complete', (choices) => {
          if (choices.google_analytics) {
            gtag('event', 'intention-appel', { 'event_category': 'contact', 'event_label': 'contact_page_phone_number' })
          }
        })
      })
      hidePhoneNumber.classList.add('hide')
      phoneNumber.classList.remove('hide')
    })
  }
}
