const launchFB = () => {
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '421897455141667');
  fbq('track', 'PageView');
}

const launchGoogleAnalytics = () => {
  gtag('js', new Date());
  gtag('config', 'UA-151167193-1')
}

export const manageCookieConsent = () => {
  void 0 === window._axcb && (window._axcb = []);
  window._axcb.push(function(axeptio) {
    axeptio.on("cookies:complete", function(choices) {
      if(choices.facebook_pixel) {
        launchFB();
      }

      if(choices.google_analytics) {
        launchGoogleAnalytics();
      }
    })
  })
}
