import './alert.css'
import { postFlashAlert } from '../../shared/api_service'
import { scrollIntoView, createNodeFromHTML } from 'shared/utilities'

const displayFlashAlert = (message) => {
  const params = { message: message }
  postFlashAlert(params).then((response) => {
    const newNode = createNodeFromHTML(response.data)
    document.body.insertAdjacentElement('afterbegin', newNode)
    scrollIntoView(newNode)
    manageAlert()
  })
}

const manageAlert = () => {
  const alert = document.querySelector('.js-alert')
  if (alert) {
    setTimeout(() => {
      alert.style.opacity = 0
      alert.remove()
    }, 5000);

    const closeAlert = alert.querySelector('.js-close')

    if (closeAlert) {
      closeAlert.addEventListener('click', () => {
        alert.style.opacity = 0
        alert.remove()
      })
    }
  }
}

export { displayFlashAlert, manageAlert }
