import './home_page_popup.css'

export const manageHomePagePopup = () => {
  const popup = document.querySelector('.js-popup')
  if (popup) {
    window.onload = () => {
      setTimeout(() => {
        document.querySelector('.js-popup').style.display = "block";
      }, 2000);
    }

    const closePopup = popup.querySelector('.js-close')
    if (closePopup) {
      closePopup.addEventListener('click', () => {
        popup.style.opacity = 0
        popup.remove()
      })
    }
  }
}
