import './infobanner.css'
import { postFlashAlert } from '../../shared/api_service'
import { scrollIntoView, createNodeFromHTML } from 'shared/utilities'

const manageInfobanner = () => {
  const alert = document.querySelector('.js-alert-abscence')
  if (alert) {
    const closeAlert = alert.querySelector('.js-close')
    if (closeAlert) {
      closeAlert.addEventListener('click', () => {
        alert.style.opacity = 0
        alert.remove()
      })
    }
  }
}

export { manageInfobanner }
