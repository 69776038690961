import axios from 'axios'

export const openZendeskLink = (url) => {
  axios.get(`/api/v1/zendesk/zendesk_token`).then((response) => {
    const jwt_payload = response.data.success ? response.data.data : ''
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', 'https://helpculturepay.zendesk.com/access/jwt')
    form.setAttribute('target', '_blank')

    const jwtInput = document.createElement('input')
    jwtInput.setAttribute('type', 'hidden')
    jwtInput.setAttribute('name', 'jwt')
    jwtInput.setAttribute('value', jwt_payload)

    const returnToInput = document.createElement('input')
    returnToInput.setAttribute('type', 'hidden')
    returnToInput.setAttribute('name', 'return_to')
    returnToInput.setAttribute('value', url)

    form.appendChild(jwtInput)
    form.appendChild(returnToInput)

    document.body.appendChild(form)
    form.submit()
  })
}

// Static pages purpose
export const manageZendeskLink = () => {
  const zendeskLinks = document.querySelectorAll(".zendesk-link")

  zendeskLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault()
      openZendeskLink(link.getAttribute('href'))
    })
  })
}
