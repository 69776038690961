import './slider.css'
import Splide from '@splidejs/splide';

if (document.querySelector('.splide')) {
  var elms = document.getElementsByClassName('splide');
  if (window.innerWidth > 820 ){
    for ( var i = 0, len = elms.length; i < len; i++ ) {
      new Splide( elms[ i ], {
        autoplay: true,
        interval: 12000,
        type: 'loop',
        pauseOnHover: true,
          classes: {
            arrow: 'splide__arrow white_bg'
          },
      }).mount();
    }
  }
  else {
    for ( var i = 0, len = elms.length; i < len; i++ ) {
      new Splide( elms[ i ], {
        autoplay: true,
        interval: 12000,
        arrows: false,
        type: 'loop',
        pauseOnHover: true,
      }).mount();
    }
  }
}
